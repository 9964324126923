<template>
  <div>
      <div class="navigation" >
      <div class="left" @click="logoClick('/')">
        <img class="logo" src="../../../static/logoBig.png" alt="" mode="heightFix" height="55px" >
        <div>
          <div style="font-size: 25px">蓝英科技</div>
          <div style="font-size: 12px">Lanying Technology</div>
        </div>
      </div>
      <div class="right">
<!--        导航栏-->
        <div class="navigationBar">
          <ul @mouseleave="mouseleave">
            <li v-for="(item,indexOf) in routerListJava" :key="indexOf" @mouseenter="enter(indexOf + 1)" @click="routerPush(item.url, item.id)" :class="index == item.id ? 'p1' : ''">
              {{ item.name }}
            </li>
          </ul>
          <div ref="border" :class="classId != 0 ? 'border' : 'borderClass'"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { foregroundNavigationQueryListForShow } from '../../../api/homeNav'
export default {
  name: "index",
  data() {
    return {
      activeIndex2: '1',
      id: 1,
      classId: 1,
      // list: 26,
      list: 27,
      index: 1,
      routeList: [
        {
          path: '/',
          index: 1,
          children: []
        },{
          path: '/informa',
          index: 2,
          children: [
            {
              path: '/informa/es',
              index: 2
            },
            {
              path: '/informa/laton',
              index: 2
            },
            {
              path: '/informa/der',
              index: 2
            },
            {
              path: '/informaDet',
              index: 2
            },
          ]
        },
        {
          path: '/product',
          index: 3,
          children: [
            {
              path: "/product",
              index: 3
            }
          ]
        },
        {
          path: '/case',
          index: 4,
          children: [
            {
              path: "/caseDetails",
              index: 4
            }
          ]
        },{
          path: '/about',
          index: 5,
          children: [
            {
              path: '/about/brief',
              index: 5
            },
            {
              path: '/about/Culture',
              index: 5
            },
            {
              path: '/about/honor',
              index: 5
            },
          ]
        },{
          path: '/join',
          index: 6,
          children: [
            {
              path: '/join/contact',
              index: 6
            },
            {
              path: '/join/recruit',
              index: 6
            },
          ]
        }
      ],
      routerListJava: [],
      show:true
    }
  },
  created() {
    foregroundNavigationQueryListForShow().then(res => {
      this.routerListJava = res
      console.log('res',res)
    })
  },
  mounted() {
      //核心开始------------------------------------

      //核心结束------------------------------------
      //
      //当前位置60
      //
      //旧>新=↑
      //
      //旧的位置90
      //

    this.$refs.border.style.cssText = "margin-left:" + this.list + 'px'
    this.routeList.forEach(res => {
      if(res.path == this.$route.path) {
        this.enter(res.index)
        this.index = res.index
      }
      if(res.children.length != 0) {
        if(res.path != this.$route.path) {
          res.children.forEach(data => {
            if (data.path == this.$route.path) {
              this.enter(data.index)
              this.index = data.index
            }
          })
        }
      }
    })
  },
  methods: {
    // 导航栏跳转
    routerPush(name, id) {
      console.log('name',name)
      if(this.$route.path != name) {
        this.$router.push({ name: name })
      }
    },
    // 点击logo跳转首页
    logoClick(path) {
      this.$router.push({ path: path })
    },
    enter(index) {
      if(index > this.id) {
        this.list += (index - this.id) * 90
        this.$refs.border.style.cssText = "margin-left:" + this.list + 'px'
      }
      if(index < this.id) {
        this.list -= (this.id - index) * 90
        this.$refs.border.style.cssText = "margin-left:" + this.list + 'px'
      }
      this.id = index
    },
    mouseleave() {
      this.enter(this.index)
    }
  }
}
</script>

<style lang="less" scoped>
.navigation {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  //border-bottom: 1px solid rgba(61,61,61,.15);
  .left {
    display: flex;
    align-items: center;
    //color: #fff;
    cursor: pointer;
    .logo {
      margin: 0;
      //width: 240px;
      //height: 70px;
      //margin-top: -13px;
    }
    .logo:hover {
      cursor: pointer;
    }
    div {
      display: flex;
      flex-direction: column;
      margin-left: 6px;
      p {
        //text-align: center;
        font-size: 25px;
        //color: #000000;
        cursor: pointer;
        font-weight: 600;
      }
    }
    img {
      margin-left: 10px;
    }
  }
  .right {
    display: flex;
    align-items: center;
    .navigationBar {
      height: 100%;
      ul {
        display: flex;
        height: 100%;
        li {
          height: 100%;
          width: 90px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: 16px;
          //color: #fff;
          p {
            text-align: center;
            font-size: 16px;
            //color: rgba(102, 102, 102, 1);
            cursor: pointer;
          }
          .p1 {
            //color: #4B89DA;
          }
        }
        li:hover {
          cursor:pointer;
        }
        li:hover p {
          color: #7384AB;
        }
      }
      .border {
        width: 63px;
        border-bottom: 2px solid #4B89DA;
        transition: all .5s;
        //margin-left: 26px;
        margin-top: -20px;
      }
      .borderClass {
        display: none;
      }
    }
  }
}

.navigation{
  width: 1160px;
  margin: 0px auto;
  height: 90px;
}

</style>
