<template>
  <div class="information">
    <div class="titleContnet">
      <div class="title">其他产品</div>
<!--      <div class="navtry"><homeIndustry @buttonEvent="showDialog"></homeIndustry></div>-->
    </div>
    <div class="content">
      <div class="title" v-if="item.isMajor === 'N'" v-for="(item,index) in industryList" :key="index">
        <div class="img">
          <el-carousel trigger="click">
            <el-carousel-item v-for="(picItem,picIndex) in item.picList" :key="picIndex">
              <img :src="picItem.url" width="100%" height="100%" alt="">-->
              <div class="synopsis">
                <div class="p1">{{ picItem.picTitle }}</div>
                <div class="p2">{{ picItem.picDesc }}</div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { productQueryList } from "../../../api/otherPRoductsAdmin";
import homeIndustry from '../navigation/homeIndustry'

export default {
  name: "index",
  components:{
    homeIndustry
  },
  data(){
    return{
      current:1,
      size:3,
      params:{
        typeId:1
      },
      industryList:[]
    }
  },
  created() {
    this.getList();
  },
  methods:{
    getList(){
      productQueryList().then(res => {
        this.industryList = res
      })
    },
    details(id) {
      this.$router.push({name: 'informaDet', query: {id: id}})
    },
    async showDialog(id){
      await this.getId(id);
      this.getList();
    },
    getId(id){
      this.params.typeId = id
    }
  }
}
</script>

<style scoped lang="less">
.titleContnet{
  margin: 49px 0;
  //display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.information {
  margin: 30px 0;
}
.title {
  text-align: center;
  font-size: 24px;
  //font-weight: 600;
  line-height: 30px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
.content {
  position: relative;
  left: 22px;
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  .title {
    width: 380px;
    color: #000;
    margin-right: 10px;
    overflow: hidden;
    margin-bottom: 10px;
    .img {
      width: 100%;
      height: 282px;
      border-radius: 10px;
      overflow: hidden;
      img {
        transition: all .5s;
      }
      img:hover {
        transform: scale(1.05);
      }
    }
    .name {
      font-size: 18px;
      margin: 30px 10px;
      font-weight: 600;
      height: 27px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box !important;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .details {
      height: 115px;
      font-size: 14px;
      margin: 25px 13px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box !important;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }
    .button {
      margin: -36px 15px 20px 10px;
      width: 150px;
      height: 46px;
      background: url("../../../static/image/home/组48.png") no-repeat;
    }
    .button:hover {
      cursor:pointer;
      //margin: 22px 15px;
      margin: -36px 15px 20px 10px;
      width: 150px;
      height: 46px;
      background: url("../../../static/image/home/组48.png") no-repeat;
    }
  }
}
.synopsis:hover{
  box-shadow: 2px 4px 20px #ccc;
  cursor:pointer;
  background-color: #fff;
}
.synopsis {
  //width: 400px;
  width: 80%;
  background-color: #ffffff6e;
  position: relative;
  margin-top: -133px;
  padding: 0px 20px;
  transition-duration: 1s;
  text-align: left;
  height: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  .p1 {
    font-size: 20px;
    margin: 10px 0;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .p2 {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
</style>
