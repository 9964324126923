export function scrollTop (  ) {
  let oldTop = 0; //旧数据，初始为0
  let show;
  // 将自定义方法绑定到窗口滚动条事件
  window.onscroll = () => {
    // let top = document.scrollingElement.scrollTop; //触发滚动条，记录数值
    // //旧数据大于当前位置，表示滚动条top向上滚动
    // if (oldTop > top) {
    //   show = false;
    //   console.log("↑");
    //   // top: -18%;
    // } else {
    //   //相反...
    //   show = true;
    //   console.log("↓");
    // }
    // oldTop = top;//更新旧的位置
    // return show
  }
}
