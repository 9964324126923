
<template>
  <div class="wrap">
    <div id="map" style="width: 1200px"></div>
  </div>
</template>

<script>
let map = null;
export default {
  data() {
    return {
      coordinate:[116.29844, 40.05339],
      markerData: [
        {
          id:1,
          position: [116.43, 39.92],
          content: "塔吊2",
        },
      ],
    };
  },
  mounted() {
    this.getMap();
  },
  methods: {
    getMap() {
      map = new AMap.Map("map", {
        mapStyle: "amap://styles/fresh", //设置地图的显示样式
        zoom: 13, //设置地图显示的缩放级别
        center: this.coordinate, //设置地图中心点坐标
      });
      this.markerData.forEach((item) => {
        var marker = new AMap.Marker({
          icon: 'https://www.bjccjy.com/lanying_file/bz.png', // 添加 Icon 图标 URL
          position: item.position, // 基点位置
          offset: new AMap.Pixel(-0, -0), // 相对于基点的偏移位置
        });
        marker.setMap(map);
        console.log('item.code',item.code)
        let markerContent = '<div class="info">' + item.code + "</div>";
  });
    },
    // initMap (map) {
    //   var map = new AMap.Map("container", {
    //     zoomEnable:true, //是否禁止缩放
    //     zoom: 12, //缩放比例
    //     dragEnable: false,//禁止拖动
    //     cursor: 'hand' // 鼠标在地图上的表现形式，小手
    //   });
    //   // 初始化工具条
    //   map.plugin(["AMap.ToolBar"], function() {
    //     map.addControl(new AMap.ToolBar());
    //   });
    // }
  },
};
</script>
<style scoped>
#map {
  width: 100%;
  height: 750px;
}
/deep/.amap-icon img{
  width: 40px;
  height: 42px;
}

</style>
