<template>
  <div class="box1">
    <div class="box2">
      <div class="box" v-for="(item, index) in columns" :key="index">
        <div class="big">
          <img :src="item.image" alt="" />
        </div>
        <div class="smallbox">
          <h3>{{ item.title }}</h3>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'index',
  props: ['title', 'type', 'content'],
  components: {
    // headerNav
  },
  data() {
    return {
      columns: [
        // 列表展示的column
        {
          title: `成立${this.getDay('2017')}年`,
          image: require('@/static/image/home/dashuju.png'),
          //   houseoutlined
        },
        {
          title: '技术成员100+',
          image: require('@/static/image/home/user.png'),
        },
        {
          title: '合作企业50+',
          image: require('@/static/image/home/xiangmu.png'),
        },
        {
          title: '项目经验200+',
          image: require('@/static/image/home/houseoutlined.png'),
        },
      ],
    }
  },

  computed: {},
  mounted() {},
  methods: {
    getDay(a) {
      let year1 = this.getNowFormatDate() + '';
      a = new Date(a.replace(/-/g, '/'));
      let b = new Date(year1.replace(/-/g, '/'));
      var d = Math.abs(a.getTime() - b.getTime()) / 1000 / 24 / 60 / 60;
      var year = Math.floor(d / 365);//不整除取最小的年数或者直接进位（Math.ceil），或者四舍五入Math.round，自己改这个罗
      return year;
    },
    getNowFormatDate() {
      let date = new Date(),
        year = date.getFullYear(), //获取完整的年份(4位)
        month = date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
        strDate = date.getDate() // 获取当前日(1-31)
      if (month < 10) month = `0${month}` // 如果月份是个位数，在前面补0
      if (strDate < 10) strDate = `0${strDate}` // 如果日是个位数，在前面补0
      return year
    }
  },
}
</script>
<style scoped lang="less">
.box {
  //transition: all 0.5s;
//   width: 25%;
//   float: left;
//   display: flex;
//   justify-content: center;
//   padding: 15px 0;
//   border-radius: 10px;
//   align-items: center;
}
.box:hover {
  //transform: scale(1.05);
  box-shadow: 0 0 15px #ccc;
   width: 25%;
  float: left;
  display: flex;
  justify-content: center;
  //padding: 15px 0;
  border-radius: 10px;
  align-items: center;
  //cursor: pointer;
}
.box1 {
  width: 100%;
  padding: 25px 0;
  border-bottom: #ccc 1px solid;
  //   height: 90%;
  //   background: red;
}
.box2 {
  display: flex;
  width: 90%;
  margin: 0 auto;
}
.box:hover {
  cursor: pointer;
}
.box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 100px;
  //   border: 1px solid;
  position: relative;
  //   margin-top: -116px;
  //   padding: 19px 15px;
  //transition-duration: 1s;
  //   background: deeppink;
}
.big {
  display: flex;
  width: 60px;
  height: 66px;
  justify-content: center;
  align-items: center;
  //   background: red;
  background-size: 100% 100%;
  background-image: url(../../../static/image/home/tsbj.png);

  //   background: red;
}
.smallbox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 80px;
  padding-left: 20px;
  //   background: red;
}
p {
  text-align: center;
}
img {
  width: 70%;
  height: 70%;
}
</style>
