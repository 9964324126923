<template>
  <div>
    <div class="footers">
      <div class="content home">
        <div class="left">
          <img class="logo" src="../../../static/LOGO.png" alt="">
          <div>
<!--            <p>logo</p>-->
          </div>
        </div>
        <div class="middle">
          <div class="title">
              <div class="name">关于我们</div>
              <div class="option">
                <div>公司介绍</div>
                <div>联系我们</div>
                <div>商务合作</div>
                <div>招贤纳士</div>
              </div>
          </div>
          <div class="title">
              <div class="name">案例</div>
              <div class="option">
                <div>成功案例</div>
                <div>案例详情</div>
              </div>
          </div>
          <div class="title">
              <div class="name">动态</div>
              <div class="option">
                <div>企业动态</div>
                <div>最新资讯</div>
                <div>行业前沿</div>
              </div>
          </div>
          <div class="title">
              <div class="name">联系</div>
              <div class="option">
                <div>联系方式</div>
                <div>招贤纳士</div>
              </div>
          </div>
        </div>
        <div class="right">
          <div class="qrCode">
            <div>
              <img src="@/assets/duke.jpg" alt="">
              <p>杜克数字</p>
            </div>
            <div>
              <img src="@/assets/lanying.jpg" alt="">
              <p>蓝英科技</p>
            </div>
          </div>
          <div class="hotline">
            客服热线
          </div>
          <div class="phone">150-1043-3720</div>
        </div>
      </div>
      <div class="filing home">
        <div class="head">
          <div class="name">产权保护</div>
          <div class="border"></div>
          <div class="name">免费说明</div>
          <div class="border"></div>
          <div class="name">联系我们</div>
          <div class="border"></div>
          <div class="name">媒体资源</div>
        </div>
        <div class="text">
          <div>北京蓝英科技有限公司</div>
          <div>
            <a style="color: #fff" href="https://beian.miit.gov.cn/" target="_blank">京ICP备2022017330号-1</a>
          </div>
          <div>Copyright©2022蓝英科技 All Rights Reserved</div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "index"
}
</script>

<style lang="less" scoped>
.footers {
  padding-top: 1px;
  height: 450px;
  color: #fff;
  min-width: 1200px;
  background: url("../../../static/image/矩形3.png") no-repeat center center;
  .content {
    width: 1160px !important;
    height: 240px;
    margin-top: 100px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #8c8c8c;
    .middle {
      display: flex;
      .title {
        width: 130px;
        .name {
          font-size: 14px;
          margin-bottom: 30px;
          text-align: center;
        }
        .option {
          font-size: 12px;
          color: #d2d2d2;
          text-align: center;
          div {
            margin-top: 15px;
          }
          div:hover {
            color: #8c8c8c;
            cursor: pointer;
          }
          margin-bottom: 50px;
        }
      }
    }
    .left {
      width: 200px;
      //img {
      //  margin-top: -31px;
      //  width: 100%;
      //}
    }
    .right{
      margin-right: 20px;
      .hotline {
        margin-top: 20px;
        font-size: 14px;
        font-weight: 600;
      }
      .phone {
        margin-top: 15px;
        font-size: 30px;
        font-weight: 600;
        font-style: italic;
      }
      .remind {
        font-size: 12px;
        color: #d2d2d2;
      }
      .qrCode {
        display: flex;
        div {
          margin-left: 12px;
          img {
            width: 90px;
            height: 90px;
          }
          p {
            text-align: center;
            font-size: 12px;
            color: #d2d2d2;
            margin-top: 10px;
          }
        }
      }
    }
  }
  .filing {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #d2d2d2;
    .head {
      display: flex;
      font-size: 14px;
      margin-bottom: 15px;
      .border {
        border-left: 1px solid #d2d2d2;
        margin: 0 15px;
      }
    }
    .text {
      font-size: 12px;
      display: flex;
      div {
        margin: 0 5px;
      }
    }
  }
}
.logo {
  margin: 0;
  width: 140px;
  height: 150px;
}
</style>
