<template>
  <div class="successfulCase">
    <headerVue
      title="优秀案例"
      content="EXCELLENT CASES"
      type="1"
      style="margin-bottom: 30px"
    />
    <div class="content home">
      <!-- <swiper :options="swiperOption" ref="mySwiper"> -->
      <div class="box" v-for="(item, index) in imgList" :key="index">
        <div class="block">
          <div class="wid" @click="details(item.id)">
            <img :src="item.picPath" alt="" width="200" height="100" />
            <div class="synopsis">
              <div class="p1">
                <p>{{ item.title }}</p>
              </div>
              <div class="p2">
                {{ item.shortDesc }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </swiper> -->
      <!--      <div class="hidden">-->
      <!--        <div class="block">-->
      <!--          <img src="../../../static/image/home/轮播图.png" alt="">-->
      <!--          <div class="synopsis">-->
      <!--            <div class="p1">从全球视野看全球化设计</div>-->
      <!--            <div class="p2">十年前“国际化”是高大上的象征，今天“全球化”是热门词。随着全球联系的不断加强，不仅是国与国的关系写照，也日渐普及到每个人的生活轨迹</div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div class="block">-->
      <!--          <img src="../../../static/image/home/轮播图.png" alt="">-->
      <!--          <div class="synopsis">-->
      <!--            <div class="p1">从全球视野看全球化设计</div>-->
      <!--            <div class="p2">十年前“国际化”是高大上的象征，今天“全球化”是热门词。随着全球联系的不断加强，不仅是国与国的关系写照，也日渐普及到每个人的生活轨迹</div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div class="block">-->
      <!--          <img src="../../../static/image/home/轮播图.png" alt="">-->
      <!--          <div class="synopsis">-->
      <!--            <div class="p1">从全球视野看全球化设计</div>-->
      <!--            <div class="p2">十年前“国际化”是高大上的象征，今天“全球化”是热门词。随着全球联系的不断加强，不仅是国与国的关系写照，也日渐普及到每个人的生活轨迹</div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div class="block">-->
      <!--          <img src="../../../static/image/home/轮播图.png" alt="">-->
      <!--          <div class="synopsis">-->
      <!--            <div class="p1">从全球视野看全球化设计</div>-->
      <!--            <div class="p2">十年前“国际化”是高大上的象征，今天“全球化”是热门词。随着全球联系的不断加强，不仅是国与国的关系写照，也日渐普及到每个人的生活轨迹</div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
// 引入插件
// import { swiper, swiperSlide } from 'vue-awesome-swiper'
// import 'swiper/dist/css/swiper.css'
import { filePath } from '@/api/public'
import headerVue from '@/components/header.vue'
import { showList } from "../../../api/caseAdmin";
export default {
  name: 'index',
  components: {
    // swiper,
    // swiperSlide,
    headerVue,
  },
  data() {
    return {
      size: 6,
      current: 1,
      params: {
        typeId: 1,
      },
      swiperOption: {
        loop: true,
        slidesPerView: 2,
        // loopedSlides: 5,
        // spaceBetween: 20,
        // loopAdditionalSlides : 1,
      },
      imgList: [],
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper
    },
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      showList().then((res) => {
        filePath().then((httpsPath) => {
          res.forEach((item) => {
            item.picPath = httpsPath + item.picPath
          })
        })
        this.imgList = res
      })
    },
    // 案例详情
    details(id) {
      this.$router.push({ name: 'caseDetails', query: { id: id } })
    },
    goCase() {
      this.$router.push({
        path: '/case',
        query: {
          picId: '3',
        },
      })
      // this.$router.push('/caseDetails')
    },
  },
}
</script>

<style scoped lang="less">
.box {
  display: flex;
}
.wid {
  transition: all 0.5s;
}
.wid:hover {
  transform: scale(1.05);
  cursor: pointer;
}
.border1 {
  width: 74px;
  border: 1px solid;
  margin-right: 10px;
  border-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(51, 51, 51, 1)
  )
  1 1;
}
.border2 {
  width: 74px;
  border: 1px solid;
  margin-left: 10px;
  border-image: linear-gradient(
    90deg,
    rgba(51, 51, 51, 1),
    rgba(255, 255, 255, 0)
  )
  1 1;
}
.title2 {
  width: 130px;
  height: 28px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 28px;
}
.successfulCase {
  margin-top: 80px;
  margin-bottom: 40px;
  .head {
    margin: 30px 0;
    display: flex;
    justify-content: space-between;
    .title {
      font-size: 24px;
      font-weight: 600;
      line-height: 30px;
    }
    .more {
      color: #4b89da;
      cursor: pointer;
    }
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }
}

.block {
  width: 380px;
  height: 254px;
  margin-bottom: 30px;
  .wid {
    width: 100%;
    height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .synopsis:hover {
    cursor: pointer;
  }
  .synopsis {
    width: 89.5%;
    padding: 12px 20px;
    padding-top: 4px;
    display: flex;
    flex-direction: column;
    color: white;
    background-color: rgba(27, 27, 27, 0.6);
    position: relative;
    margin-top: -86.5px;
    transition-duration: 1s;
    .p1 {
      font-size: 13px;
      margin: 10px 0;
      // margin-left: -60px;
    }
    .p2 {
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

.content {
  .swiper-container {
    position: relative;
    background: rgba(0, 0, 0, 0);
    .swiper-slide {
      width: 600px !important;
    }
  }
}
</style>
