<template>
  <div
    class="home"
    style="display: flex; flex-direction: column; align-items: center"
  >
    <div style="display: flex; align-items: center; justify-content: center;flex-direction: column;">
      <div class="border1">{{ title }}</div>

      <div class="border2"></div>
    </div>
    <div class="title2">
      {{ content || '' }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'index',
  props: ['title', 'type', 'content'],
  components: {
    // headerNav
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  methods: {},
}
</script>
<style scoped lang="less">
.border1 {
  //width: 157px;
  // height: 4px;
  font-size: 36px;
  color: #333;
  //margin-bottom: 52px;
  position: relative;
  letter-spacing: 1px;
  font-weight: bold;
  // border: 10px solid;
  // margin-right: 20px;
  // margin-top: 20px;
  // border-image: linear-gradient(
  //     90deg,
  //     rgba(255, 255, 255, 0),
  //     rgba(51, 51, 51, 1)
  //   )
}
.border2 {
  width: 65px;
  height: 3px;
  margin: 12px;
  background: #3433b3;
}
.title2 {
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 28px;
}
</style>
