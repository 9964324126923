<template>
<div>
  <div class="carousel" v-for="(item, index) in newList" :key="index">
<!--    <div class="title">{{ item.name }}</div>-->
    <headerVue
      :title="item.name"
      type="1"
    />
    <el-carousel  trigger="click" height="450px" style="margin-top: 30px;" >
      <el-carousel-item v-for="(itemPic,indexPic) in item.picList" :key="indexPic">
        <img :src="itemPic.url" style="width: 100%; height: 100%;" alt="">
        <div class="synopsis">
          <div class="p1">{{ itemPic.picTitle }}</div>
          <div class="p2">{{ itemPic.picDesc }}</div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</div>
</template>
<script>
import { productQueryList } from "../../../api/otherPRoductsAdmin";
import { rotatePage, newsQueryList, rotateQueryList } from "../../../api/contactInformation";
import headerVue from '@/components/header.vue'
export default {
  name: "index",
  components: {
    headerVue,
  },
  data(){
    return{
      newList:[],
      recordsTotal: null, // 数据总数
      current:1,
      size:6,
      params:{
        name:''
      }
    }
  },
  created() {
    productQueryList().then(res => {
      this.newList = res
    })


  }
  // methods:{
  //   contactUsQueryObject
  // }
}
</script>

<style scoped lang="less">
.carousel {
  min-width: 100%;
  margin: 0 auto;
  margin-bottom: 80px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
.title {
  margin-bottom: 27px;
  text-align: center;
  font-size: 24px;
  //font-weight: 600;
  line-height: 30px;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
///deep/.el-carousel__container{
//  top: 75px;
//}
/deep/.el-carousel--horizontal{
  overflow: hidden !important;
}
///deep/.el-carousel__arrow{
//  top: 44%;
//}
.synopsis:hover{
  box-shadow: 2px 4px 20px #ccc;
  cursor:pointer;
  background-color: #fff;
}
.synopsis {
  //width: 400px;
  width: 80%;
  background-color: #ffffff6e;
  position: relative;
  margin-top: -115px;
  height: 113px;
  padding: 19px 20px;
  transition-duration:1s;
  .p1 {
    font-size: 20px;
    margin: 10px 0;
    font-weight: 600;
  }
  .p2 {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
</style>
