<template>
  <div class="information">
    <headerVue title="行业资讯" content="INDUSTRY INFORMATION" type="1" style="margin-bottom: 30px" />
    <div class="buttons">
      <el-button :type="showDialogId == item.id ? 'primary' : ''" v-for="(item, index) in newTypyeList" :key="index" @click="showDialog(item.id)">{{ item.name }}</el-button>
    </div>
    <div class="content1">
      <div class="title1" v-for="(item,index) in industryList" @click="details(item.id)" :key="index">
        <div class="img">
          <img :src="item.picPathHttpUrl" width="100%" height="100%" alt="">
        </div>
        <div class="text">
          <div class="name">{{ item.title }}</div>
          <div class="overview">
            <div class="details">
              {{ item.shortDesc }}
            </div>
            <div class="right"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { newsNewsTypeList, newsQueryList } from "../../../api/industryInformation";
import headerVue from '@/components/header.vue'

export default {
  name: "index",
  components: {
    headerVue
  },
  data(){
    return{
      current:1,
      size:3,
      params:{
        typeId:1
      },
      industryList:[],
      newTypyeList: [],
      showDialogId: null,
    }
  },
  created() {
    this.getList();
    newsNewsTypeList().then(res => {
      this.newTypyeList = res
      this.showDialogId = res[0].id;
    })
  },
  methods:{
    getList(){
      newsQueryList(this.current, this.size, this.params).then(res => {
        console.log('res',res)
        this.industryList = res.records
      })
    },
    details(id) {
      this.$router.push({name: 'informaDet', query: {id: id}})
    },
  async showDialog(id){
      await this.getId(id);
      this.showDialogId = id;
         this.getList();
    },
    getId(id){
      this.params.typeId = id
    }
  }
}
</script>

<style scoped lang="less">
.titleContnet{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.information {
  margin-top: 60px;
}
.title {
  font-size: 24px;
  //font-weight: 600;
  line-height: 30px;
}

.content1 {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .title1 {
    width: 380px;
    height: 250px;
    color: #000;
    margin-right: 10px;
    overflow: hidden;
    cursor: pointer;
    .img {
      width: 100%;
      height: 154px;
      //border-radius: 10px;
      overflow: hidden;
    }
    .text {
      padding: 14px;
      padding-bottom: 28px;
      background-color: #fff;
      .name {
        font-size: 20px;
        margin-bottom: 12px;
        font-weight: 500;
        line-height: 28px;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box !important;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .overview {
        display: flex;
        align-items: flex-start;
        height: 14px;
        .details {
          width: 85%;
          margin-right: 26px;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box !important;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .right {
          background: url("../../../static/image/home/right.png") no-repeat;
          background-size: 100% 100%;
          width: 22px;
          height: 22px;
        }
      }
    }
  }
  .title1:hover .text {
    //width: 329px;
    width: 93%;
    -webkit-animation: scale-up-ver-bottom 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: scale-up-ver-bottom 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    padding: 14px;
    padding-top: 31px;
    padding-bottom: 56px;
    background-color: #026FD3;
    color: #fff;
    //position: absolute;
    margin-top: -45px;
    .name {
      font-size: 20px;
      margin-bottom: 12px;
      font-weight: 500;
      line-height: 28px;
      color: #fff;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box !important;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .overview {
      display: flex;
      align-items: flex-start;
      height: 14px;
      .details {
        width: 85%;
        margin-right: 26px;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box !important;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      .right {
        background: url("../../../static/image/home/right1.png") no-repeat;
        background-size: 100% 100%;
        width: 22px;
        height: 22px;
      }
    }
  }
}

.buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 38px;
}


@-webkit-keyframes scale-up-ver-bottom {
  0% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
}
@keyframes scale-up-ver-bottom {
  0% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
}
</style>
