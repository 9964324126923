<template>
  <div class="cooperate">
    <headerVue title="合作模式" content="已结果为导向，建立长期稳定的合作" type="1" style="margin-bottom: 30px" />
    <p class=" wow fadeInUp animated" data-wow-delay="1s" style="text-align: center; margin-bottom: 30px;font-size: 20px;color: #333;visibility: visible; animation-delay: 1s; animation-name: fadeInUp;">提供独有的企业数据与运维服务，保障系统全方位适用及安全运行 </p>
    <ul style="display: flex;align-items: center;justify-content: space-between;">
      <li class=" wow fadeInUp animated" data-wow-delay="1s" style="visibility: visible; animation-delay: 1s; animation-name: fadeInUp;">
        <a>
          <div class="i">
            <i></i>
            <img src="@/static/image/home/users.png" alt="">
          </div>
          <h4>服务合作</h4>
          <p>联合打造更具备市场领先性的产品及解决方案</p>
          <span></span>
        </a>
      </li>
      <li class=" wow fadeInUp animated" data-wow-delay="1s" style="visibility: visible; animation-delay: 1s; animation-name: fadeInUp;">
        <a>
          <div class="i">
            <i></i>
            <img src="@/static/image/home/architecture.png" alt="">
          </div>
          <h4>渠道合作</h4>
          <p>在垂直行业、区域城市，拥有丰富资源的伙伴</p>
          <span></span>
        </a>
      </li>
      <li class=" wow fadeInUp animated" data-wow-delay="1s" style="visibility: visible; animation-delay: 1s; animation-name: fadeInUp;">
        <a>
          <div class="i">
            <i></i>
            <img src="@/static/image/home/cooperate.png" alt="">
          </div>
          <h4>代理合作</h4>
          <p>推广及销售科诚产品及服务</p>
          <span></span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import headerVue from '@/components/header.vue'
export default {
  name: "index",
  components: {
    headerVue
  },
  methods:{

  }
};
</script>

<style scoped lang="less">
.cooperate {
  min-width: 1200px;
  //background: rgb(247,247,247);
  padding-top: 30px;
}

li {
  width: 31.3333%;
  //float: left;
  margin: 0 0% 3% 0;
  box-shadow: 1px 4px 20px -2px rgba(0,0,0,.1);
  padding: 65px 25px 40px;
  transition: all .35s ease-in-out;
  text-align: center;
  box-sizing: border-box;
  position: relative;
  span {
    width: 0;
    height: 3px;
    position: absolute;
    left: 0;
    bottom: 0;
    transition: all 0.3s;
    -wbkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    -moz-transition: all 0.3s;
    background: #ff6626;
  }
}

li:hover {
  box-shadow: 1px 4px 20px -2px rgba(0,0,0,.2);
  transition: all 0.3s;
  -wbkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  .i {
    -webkit-transform: rotateY(360deg);
    -moz-transform: rotateY(360deg);
    -o-transform: rotateY(360deg);
    -ms-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
  span {
    width: 100%;
    height: 3px;
    transition: all 0.3s;
    -wbkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    -moz-transition: all 0.3s;
  }
}

a {
  color: #91989f;
  text-decoration: none;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -ms-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.i {
  margin-bottom: 30px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  display: inline-block;
  font-size: 55px;
  color: #4d69fa;
  position: relative;
  img {
    width: 55px;
    height: 55px;
  }
  i {
    position: absolute;
    left: -15px;
    top: -25px;
    content: "";
    height: 60px;
    width: 60px;
    background: #ff6626;
    z-index: -1;
    opacity: .07;
    transform: rotate(45deg);
    border-radius: 5px;
    z-index: -1;
  }
}

h4 {
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: bold;
}

p {
  font-size: 16px;
  color: #555;
  line-height: 1.8;
}
</style>