import router from "./router";

export default function permision() {
  router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requireAuth)) {
      // console.log('record',record)
      // 判断该路由是否须要登陆权限
      // this route requires auth, check if logged in
      // if not, redirect to login page.
      // 确保必定要调用next()
      let token = sessionStorage.getItem('token')
      token = token.replace('"', '').replace('"', '')
      // console.log('token', token)
      if (token == null) {
        // console.log('tokenweinull')
        next({ name: '/main' })
      } else {
        console.log('next')
        next()
      }
      // if (to.matched.length === 0) {
      // next({name: '404'}) // 判断此跳转路由的来源路由是否存在，存在的状况跳转到来源路由，不然跳转到404页面
      // }
      // } else {
    } else {
      next()
    }
  });
}




