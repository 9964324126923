import {get,post} from "../http/index";

// 详情
export const contactUsQueryObject = () => get('/contactUs/queryObject');

// 保存
export const contactUsUpdate = (params) => post('/contactUs/update', params);

//轮播图分页
export const rotateQueryList = () => get('/rotate/queryList');


