<template>
  <div class="contact">
    <div class="title">与我们联系</div>
    <div>
      <contactInformation />
    </div>
  </div>
</template>

<script>
import contactInformation from '@/pages/join/contactInformation'
export default {
  name: "index",
  components: {
    contactInformation
  }
}
</script>

<style scoped lang="less">
.contact {
  margin-bottom: 300px;
  //margin-top: -60px;
  margin-top: 10px;
}
.title {
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  //margin-bottom: 60px;
  margin: 35px 0;
}
</style>
