import {get,post} from "../http/index";

//集合
export const foregroundNavigationQueryList = () => get('/foregroundNavigation/queryList')

//官网显示集合
export const foregroundNavigationQueryListForShow = () => get('/foregroundNavigation/queryListForShow')

//详情
export const foregroundNavigationDetails = (params) => get('/foregroundNavigation/detail/' + params);

//保存新增
export const foregroundNavigationSave = (params) => post('/foregroundNavigation/save',params)

// 编辑数据保存
export const foregroundNavigationUpdate = (params) => post('/foregroundNavigation/update',params)

// 图片上传集合
export const uploadFile = (params, folder) => post('/fileProcessing/uploadFile', params, folder)

// 获取图片链接
export const webServerHttpUrl = () => get('/fileProcessing/webServerHttpUrl')

//排序
export const foregroundNavigationSort = (id, upOrDown) =>get('/foregroundNavigation/sort/' + upOrDown + "/" + id);
