<template>
  <div class="headImg" :style="`height: calc(${fullHeight}px - 300px)`">
    <figure class="mast__bg js-parallax" :style="`background-image: url(${dataObj.picPathHttpUrl}); transform: translate3d(0px, 0px, 0px);`"></figure>
<!--    <img class="bannerIamg" :src="dataObj.picPathHttpUrl"  width="100%" alt="" />-->
    <div class="grid">
      <header class="mast__header" style="margin-top: -84px">
        <h1 class="mast__title">{{ dataObj.name }}</h1>
        <h1 class="mast__title">{{ dataObj.enName.toUpperCase() }}</h1>
      </header>
    </div>
  </div>
</template>

<script>
import { foregroundNavigationDetails } from '../../../api/homeNav'

export default {
  name: 'index',
  data(){
    return{
      picId: null,
      dataObj:{},
      fullWidth:document.documentElement.clientWidth,
      fullHeight:document.documentElement.clientHeight
    }
  },
  created() {
    this.picId = 4

    localStorage.setItem('caseId', this.picId)
    let localId = localStorage.getItem('caseId')
    if(this.picId != null){
      foregroundNavigationDetails(this.picId).then(res => {
        this.dataObj = res
      })
    } else {
      foregroundNavigationDetails(localId || 3).then(res => {
        this.dataObj = res
      })
    }
  },
}
</script>

<style scoped lang="less">
.headImg {
  ///*min-width: 1200px;*/
  ////height: 700px;
  ///*background: url("../../../static/image/case/headImg.jpg") no-repeat center center;*/
  //margin: 0 auto;
  //height: 340px;
  //overflow: hidden;
  ////img{
  ////  height: 500px;
  ////  width: 100%;
  ////}



  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding-top: 6em;
  padding-bottom: 4em;
  text-align: center;
  overflow: hidden;
  color: #fff;
  background-color: #111;
  //height: 27.2em;
  //height: 400px;
  max-height: 70vh;
  margin-bottom: 60px;
}

.mast__bg {
  z-index: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
  opacity: 0.7;
  -webkit-animation: fade-in-mast 1.5s 1s ease both;
  animation: fade-in-mast 1.5s 1s ease both;
}

.grid {
  position: relative;
  -ms-flex-item-align: center;
  align-self: center;
  margin: 0;
}
.mast_headr {
  position: relative;
  z-index: 4;
  width: 100%;
  margin: 0 auto;
  padding: 0 2%;
}
.mast__title {
  font-size: 40px;
  color: #fff;
  font-family: gotham,"Helvetica Neue",sans-serif;
  font-weight: 100;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  line-height: 1.4;
}

h1 {
  margin: 0;
}
</style>
