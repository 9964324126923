<template>
<div>
<!--  <div class="title">优秀案例</div>-->
  <headerVue :title="dataObj.name" :content="dataObj.enName.toUpperCase()" type="1" style="margin-bottom: 30px" />
  <!--  <div class="waterfull">-->
<!--    <div class="v-waterfall-content" id="v-waterfall">-->
<!--      <div @click="details(img.id)" v-for="(img, index) in waterfallList" :key="index" class="v-waterfall-item" :style="{top:img.top+'px',left:img.left+'px',width:waterfallImgWidth+'px',height:img.height}">-->
<!--        <img :src="img.src" alt="">-->
<!--                <span class="titleStyle">{{img.title}}</span>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
  <div class="content home">
    <div class="box" v-for="(item, index) in waterfallList" :key="index">
      <div class="block">
        <div class="wid" @click="details(item.id)">
          <img :src="item.picPath" alt="" width="200" height="100" />
          <div class="synopsis">
            <div class="p1">
              <p>{{ item.title }}</p>
            </div>
            <div class="p2">
              {{ item.shortDesc }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="pageInfo">
    <el-pagination
      @current-change="handleCurrentChange"
      :current-page="current"
      :page-size="size"
      layout="total, prev, pager, next, jumper"
      :total="recordsTotal"
      style="margin-top: -15px">
    </el-pagination>
  </div>
</div>
</template>

<script>
import { newsQueryList } from "@/api/caseAdmin";
import { filePath } from '@/api/public'
import headerVue from '@/components/header.vue'
import { foregroundNavigationDetails } from "../../../api/homeNav";

export default {
  name: "v-waterfall",
  components: {
    headerVue
  },
  data() {
    return {
      newList:[],
      recordsTotal: null, // 数据总数
      current:1,
      size:9,
      params:{
        typeId:1
      },
      waterfallList: [],
      imgArr: [
        require('../../../static/image/case/1.png'),
        require('../../../static/image/case/2.png'),
        require('../../../static/image/case/3.png'),
        require('../../../static/image/case/4.png'),
        require('../../../static/image/case/5.png'),
        require('../../../static/image/case/6.jpg'),
        require('../../../static/image/case/7.png'),
        require('../../../static/image/case/8.jpg'),
        require('../../../static/image/case/9.png'),
      ],
      waterfallImgWidth: 382,// 每个盒子的宽度
      waterfallImgCol: 3,// 瀑布流的列数
      waterfallImgRight: 10,// 每个盒子的右padding
      waterfallImgBottom: 10,// 每个盒子的下padding
      waterfallDeviationHeight: [],
      imgList: [],
      httpsPath: null,
      dataObj:{},
    }
  },
  created() {
    this.waterfallList = []
    this.getList()
    // // 触发入口
    // for (let i = 0; i < this.imgArr.length; i++) {
    //   // this.imgList.push(this.imgArr[Math.round(Math.random() * 8)]);// 图片随机显示
    //   console.log('this.imgArr[i]',this.imgArr[i])
    //   this.imgList.push(this.imgArr[i]);
    // }
  },
  mounted() {
    foregroundNavigationDetails(4).then(res => {
      this.dataObj = res
    })
  },
  methods: {
    getList(){
      newsQueryList(this.current, this.size, this.params).then(res => {
        // this.calculationWidth();
        filePath().then(data => {
          this.httpsPath = data
          res.records.forEach(item => {
            item.picPath = this.httpsPath + item.picPath;
          })
          this.waterfallList = res.records
          this.imgList = res.records
          this.recordsTotal = res.total
        })

      })
    },
    //  分页
    handleCurrentChange(val) {
      this.current = val
      this.getList()
    },
    // 案例详情
    details(id) {
      console.log('id',id)
      this.$router.push({name: 'caseDetails', query: {id: id}})
    },
    //计算每个图片的宽度或者是列数
    calculationWidth() {
      let domWidth = document.getElementById("v-waterfall").offsetWidth;
      if (!this.waterfallImgWidth && this.waterfallImgCol) {
        this.waterfallImgWidth = (domWidth - this.waterfallImgRight * this.waterfallImgCol) / this.waterfallImgCol;
      } else if (this.waterfallImgWidth && !this.waterfallImgCol) {
        this.waterfallImgCol = Math.floor(domWidth / (this.waterfallImgWidth + this.waterfallImgRight))
      }
      //初始化偏移高度数组
      this.waterfallDeviationHeight = new Array(this.waterfallImgCol);
      for (let i = 0; i < this.waterfallDeviationHeight.length; i++) {
        this.waterfallDeviationHeight[i] = 0;
      }
      this.imgPreloading()
    },
    //图片预加载
   async imgPreloading() {
     this.waterfallList = []
     await filePath().then(res => {
        this.httpsPath = res
     })
      console.log('getHttpsPath', this.httpsPath)
     console.log('this.imgList',this.imgList)
      for (let i = 0; i < this.imgList.length; i++) {
        let aImg = new Image();
        aImg.src = this.httpsPath + this.imgList[i].picPath;
        aImg.onload = aImg.onerror = (e) => {
          let imgData = {};
          console.log(aImg)
          imgData.height = this.waterfallImgWidth / aImg.width * aImg.height;
          imgData.src = this.httpsPath + this.imgList[i].picPath;
          imgData.id = this.imgList[i].id;
          imgData.title = this.imgList[i].title;// 说明文字（也可以自己写数组，或者封装json数据，都可以，但是前提是你会相关操作，这里不赘述）
          // imgData.info = '详情说明：啦啦啦啦啦';// 说明文字
          this.waterfallList.push(imgData);
          this.rankImg(imgData);
        }
      }
    },
    //瀑布流布局
    rankImg(imgData) {
      let {
        waterfallImgWidth,
        waterfallImgRight,
        waterfallImgBottom,
        waterfallDeviationHeight,
        waterfallImgCol
      } = this;
      let minIndex = this.filterMin();
      imgData.top = waterfallDeviationHeight[minIndex];
      imgData.left = minIndex * (waterfallImgRight + waterfallImgWidth);
      waterfallDeviationHeight[minIndex] += imgData.height + waterfallImgBottom;// 不加文字的盒子高度
      // waterfallDeviationHeight[minIndex] += imgData.height + waterfallImgBottom + 56;// 加了文字的盒子高度，留出文字的地方（这里设置56px）
      // console.log(imgData);
    },
    /**
     * 找到最短的列并返回下标
     * @returns {number} 下标
     */
    filterMin() {
      const min = Math.min.apply(null, this.waterfallDeviationHeight);
      return this.waterfallDeviationHeight.indexOf(min);
    }
  }
}
</script>

<style scoped lang="less">

.waterfull{
  width: 100%;
  //height: 1370px;
  margin-bottom: 60px;
}
.title {
  margin: 50px;
  text-align: center;
  font-size: 24px;
  //font-weight: 600;
  line-height: 30px;
}
.v-waterfall-content {
  width: 87%;
  height: 500px;
  position: relative;
  /* overflow-y: auto; */
  left: 36px;

  .v-waterfall-item {
    transition: all .5s;
  }
  .v-waterfall-item:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
}

.v-waterfall-item {
  /* 主要 */
  float: left;
  position: absolute;
}

.v-waterfall-item img {
  /* 主要 */
  /* width: auto;height: auto; */
  width: 90%;
  height: auto;
  /* 次要 */
  border-radius: 6px;
}
.titleStyle{
  border-radius: 0px 0px 0px 4px;
  font-size: small;
  color: #fff;
  margin: 4px;
  position: relative;
  bottom: 37px;
  /* background: red; */
  display: block;
  background: #5992dc;
  width: 60%;
  height: 30px;
  left: -3px;
  line-height: 30px;
  //text-align: ;
  /* text-align: initial; */
  padding-left: 8px;
}
.pageInfo{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 40px auto;
}

.content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.block {
  width: 380px;
  height: 254px;
  margin-bottom: 30px;
  .wid {
    width: 100%;
    height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .synopsis:hover {
    cursor: pointer;
  }
  .synopsis {
    width: 89.5%;
    padding: 12px 20px;
    padding-top: 4px;
    display: flex;
    flex-direction: column;
    color: white;
    background-color: rgba(27, 27, 27, 0.6);
    position: relative;
    margin-top: -86.5px;
    transition-duration: 1s;
    .p1 {
      font-size: 13px;
      margin: 10px 0;
      // margin-left: -60px;
    }
    .p2 {
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

.box {
  display: flex;
}
.wid {
  transition: all 0.5s;
}
.wid:hover {
  transform: scale(1.05);
  cursor: pointer;
}
</style>
