<template>
  <div class="understand">
    <headerVue title="核心业务" content="BUSINESS" type="1" />
    <div class="con">
      <ul class="ul">
        <li class="divtest wow bounceInUp animated animated" v-for="(item, index) in list" :key="index">
          <div class="hideBox">
            <div class="title">
              <img src="" alt="">
              <b>{{ item.title }}</b>
            </div>
            <div class="introduction">
              {{ item.content }}
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import headerVue from '@/components/header.vue'
export default {
  name: "index",
  components: {
    headerVue
  },
  data(){
    return{
      list:[
        {
          title:'行业软件定制',
          content:'帮助企业对业务信息系统重塑或升级改造，实现端到端互联互通的业务生态系统。',
          image:require('../../../static/image/home/bitmap4.png'),
        },
        {
          title:'小程序开发',
          content:'基于个性化、体验式的移动端产品设计，为企业量身打造小程序、公众号、移动H5等移动应用的定制开发及产品迭代。',
          image:require('../../../static/image/home/bitmap2.png'),
        },
        {
          title:'网站开发',
          content:'丰富的系统架构经验提供高并发、高可靠性、安全性系统解决方案，完成企业高端宣传的目的。',
          image:require('../../../static/image/home/bitmap3.png'),
        },
        {
          title:'手机APP开发',
          content:'高效整合Andriod/ios源生APP开发，跨平台混合APP开发，自主研发高性能框架。',
          image:require('../../../static/image/home/bitmap1.png'),
        },
        {
          title:'桌面应用开发',
          content:'通过桌面应用可以方便快捷的操作一个或者多个复杂程序，从而让复杂的工作变得简单化！',
          image:require('../../../static/image/home/bitmap4.png'),
        },
      ],
      active: false
    }
  },
  methods:{

  }
}
</script>

<style scoped lang="less">
.understand {
  height: 526px;
  min-width: 1200px;
  //background: rgb(247,247,247);
  padding-top: 60px;
}

.con {
  .ul {
    position: relative;
    z-index: 2;
    margin-bottom: 25px;
    li {
      float: left;
      width: 20%;
      height: auto;
      position: relative;
      background: #fff;
      transition: all .3s ease 0s;
      height: 344px;
      visibility: visible;
      animation-delay: 0.6s;
      .hideBox {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        padding: 30px;
        box-sizing: border-box;
        z-index: 2;
      }
      b {
        display: block;
        width: 100%;
        font-size: 20px;
        margin: 25px 0;
        color: #fff;
      }
      .introduction {
        font-size: 15px;
        line-height: 1.7;
        opacity: .7;
        color: #fff;
      }
    }
    li:hover {
      z-index: 2;
      .hideBox {
        -webkit-transform: scale(1.09);
        -moz-transform: scale(1.09);
        -o-transform: scale(1.09);
        -ms-transform: scale(1.09);
        transform: scale(1.09);
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
        box-shadow: 0 0 25px rgba(0, 0, 0, .25);
        z-index: 99;
      }
    }
  }
}
ul li.divtest:nth-child(1) .hideBox {
  background: #2c5edc;
}
ul li.divtest:nth-child(2) .hideBox {
  background: #4f6ef6;
}
ul li.divtest:nth-child(3) .hideBox {
  background: #3fd753;
}
ul li.divtest:nth-child(4) .hideBox {
  background: #24d892;
}
ul li.divtest:nth-child(5) .hideBox {
  background: #fc6733;
}
.animated {
  //-webkit-animation-duration: 1s;
  //animation-duration: 1s;
  //-webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp;
}
</style>
