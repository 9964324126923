import {get,post} from "../http/index";

//删除
export const productDelete = (params) => get('/product/delete/' + params);

//详情
export const productDetail = (params) => get('/product/detail/' + params);

//保存
export const productInsertForIdSave = (params) => post('/product/insert',params)

//修改保存
export const productUpdate = (params) => post('/product/update',params)

//列表
export const productQueryList = () => get('/product/queryList');


// 图片上传集合
export const uploadFile = (params, folder) => post('/fileProcessing/uploadFile', params, folder)

// 获取图片链接
export const webServerHttpUrlString = () => get('/fileProcessing/webServerHttpUrl')

