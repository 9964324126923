import Vue from 'vue'
import './plugins'
import http from './common/utils/http'
import router from './router/index'
import store from './store/index'
import App from './App.vue'
import Moment from 'moment'
import jm from 'vue-jsmind'
import permision from './permision'

//官网
import ElementUI from "element-ui";
import 'element-ui/lib/theme-chalk/index.css';
import globalCss from './static/css/index.less'   // 全局样式
import AMap from 'vue-amap'; //高德地图

import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'
import { scrollTop } from "./utils/scrollTop";

Vue.use(scrollTop)
Vue.use(AMap);
Vue.use(permision);
// Vue.use(loading);

AMap.initAMapApiLoader({
  key: 'bf58284272a63e084a92e74bea8240fa',
  plugin: ['AMap.Geolocation','AMap.Autocomplete', 'AMap.PlaceSearch','AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.Geolocation','AMap.Geocoder', 'AMap.AMapManager', 'AMap.Marker'],
  v: '1.4.4',
  uiVersion: '1.0'
});
Vue.use(ElementUI);
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

Vue.config.productionTip = false
Vue.use(jm)

if (window.jsMind) {
  console.log('wind')
  Vue.prototype.jsMind = window.jsMind
}

Vue.prototype.axios = http

Vue.config.productionTip = false

//全局注册格式化时间方法
Vue.filter('converTime', function(data, formatStr) {
  return Moment(data).format(formatStr);
})
new Vue({
  globalCss, // 全局样式
  store,
  router,
  render: h => h(App)
}).$mount('#app')
