/** @format */

import Vue from 'vue'
import VueRouter from 'vue-router'

import Layout from '../pages/layout/layout'

const Login = () => import('../pages/login/login')
const Error = () => import('../pages/error/index')
const HomeAdmin = () => import('../pages/home/homeAdmin')
import Home from '../pages/home/home'
import cases from '@/pages/case/index'

// 不作为main组件子页面展示的页面单独写，如下
export const loginRouter = {
  path: '/main',
  name: 'login',
  meta: {
    title: '蓝英科技后台登录'
  },
  component: Login
}

// 错误页面
export const errorRouter = {
  path: '/error/:code',
  name: 'error',
  meta: {
    title: 'error'
  },
  component: Error
}

// 作为main组件子页面展示  但不在左侧菜单显示的路由卸载otherRoter里
export const otherRouter = {
  path: '/',
    name: '/',
  component: Home,
  meta:{
  title:'北京蓝英科技有限公司',
    charset: 'utf-8',
    name: 'viewport',
    content: 'width=device-width, initial-scale=0'
}
}

// 作为Main组件的子页面展示并且在左侧菜单显示的路由写在appRouter里
export const appRouter = [
  {
    path: '/homePageSettings',
    name: 'homePageSettings',
    title: '首页设置',
    meta: {
      requireAuth: true
    },
    component: Layout,
    children: []
  },

  {
    path: '/main/about',
    name: '/main/about',
    title: '企业介绍',
    meta: {
      requireAuth: true
    },
    component: Layout,
    children: [
      {
        path: '/main/about/about',
        name: '/main/about/about',
        title: '行业资讯',
        meta: {
          requireAuth: true
        },
        component: () => import('../pages/about/about')
      }
    ]
  },
  {
    path: '/industryInformation',
    name: 'industryInformation',
    title: '首页设置',
    meta: {
      requireAuth: true
    },
    component: Layout,
    children: [
      {
        path: '/main/industryInformation/industryInformationAdmin',
        name: '/main/industryInformation/industryInformationAdmin',
        title: '行业资讯',
        meta: {
          requireAuth: true
        },
        component: () =>
          import('../pages/industryInformation/industryInformationAdmin')
      },
      {
        path: '/main/case/caseAdmin',
        name: '/main/case/caseAdmin',
        title: '优秀案例',
        meta: {
          requireAuth: true
        },
        component: () =>
          import('../pages/case/caseAdmin')
      },
      {
        path: '/main/industryInformation/rotationMap',
        name: '/main/industryInformation/rotationMap',
        title: '轮播图',
        meta: {
          requireAuth: true
        },
        component: () => import('../pages/industryInformation/rotationMap')
      },
      {
        path: '/main/contactUs/index',
        name: '/main/contactUs/index',
        title: '联系我们',
        meta: {
          requireAuth: true
        },
        component: () => import('../pages/contactUs/index'),
        children: []
      },
      {
        path: '/main/home',
        name: '/main/home',
        title: '首页',
        meta: {
          requireAuth: true
        },
        component: () => import('../pages/home/homeAdmin'),
        children: []
      },
      {
        path: '/main/join/joinAdmin',
        name: '/main/join/joinAdmin',
        title: '招贤那士',
        meta: {
          requireAuth: true
        },
        component: () => import('../pages/join/joinAdmin'),
        children: []
      },
      {
        path: '/main/case/otherProductsAdmin',
        name: '/main/case/otherProductsAdmin',
        title: '其他产品',
        meta: {
          requireAuth: true
        },
        component: () => import('../pages/case/otherProductsAdmin'),
        children: []
      },
      {
        path: '/main/home/homeNav',
        name: '/main/home/homeNav',
        title: '首页导航',
        meta: {
          requireAuth: true
        },
        component: () => import('../pages/home/homeNav'),
        children: []
      }
    ]
  },
  {
    path: '/main/systemSettings',
    name: '/main/systemSettings',
    title: '系统设置',
    meta: {
      requireAuth: true
    },
    component: Layout,
    children: [
      {
        path: '/main/sysAdmin',
        name: '/main/sysAdmin',
        title: '系统用户',
        meta: {
          requireAuth: true
        },
        component: () => import('../pages/systemSettings/sysAdmin')
      },
      // {
      //   path: '/main/customer',
      //   name: '/main/customer',
      //   title: '客服',
      //   meta: {
      //     requireAuth: true
      //   },
      //   component: () => import('../pages/systemSettings/customer')
      // },
      {
        path: 'userInfo',
        name: 'userInfo',
        title: '注册用户',
        meta: {
          requireAuth: true
        },
        component: () => import('../pages/systemSettings/userInfo')
      },
      {
        path: 'dictionary',
        name: 'dictionary',
        title: '数据字典',
        meta: {
          requireAuth: true
        },
        component: () => import('../pages/systemSettings/dictionary')
      }
    ]
  },
  // {
  //   path: '/home',
  //   name: '/home',
  //   meta: {
  //     requireAuth: true
  //   },
  //   component: Layout,
  //   children: [
  //     {
  //       path: 'HomeAdmin',
  //       name: 'HomeAdmin',
  //       title: '首页',
  //       meta: {
  //         requireAuth: true
  //       },
  //       component: HomeAdmin
  //     }
  //   ]
  // },

  {
    path: '/informa',
    name: 'informa',
    component: () => import('@/pages/industryInformation/index'),
    meta: {
      title: '行业资讯 - 蓝英科技官网',
      charset: 'utf-8',
      name: 'viewport',
      content: 'width=device-width, initial-scale=0,user-scalable=no'
    },
    redirect: '/informa/es',
    children: [
      {
        path: '/informa/es',
        name: '/informa/es',
        component: () =>  import('@/pages/industryInformation/EnterpriseDynamics'),
        meta: {
          title: '行业资讯 - 北京蓝英科技有限公司',
          charset: 'utf-8',
          name: 'viewport',
          content: 'width=1519, initial-scale=0,user-scalable=no'
        }
      },
      {
        path: '/informa/laton',
        name: 'informa/latestInformation',
        component: () => import('@/pages/industryInformation/latestInformation'),
        meta: {
          title: '行业资讯 - 北京蓝英科技有限公司',
          charset: 'utf-8',
          name: 'viewport',
          content: 'width=device-width, initial-scale=0'
        }
      },
      {
        path: '/informa/der',
        name: 'informa/der',
        component: () => import('../pages/industryInformation/designFrontier'),
        meta: {
          title: '行业资讯 - 北京蓝英科技有限公司',
          charset: 'utf-8',
          name: 'viewport',
          content: 'width=device-width, initial-scale=0'
        }
      }
    ]
  },
  {
    path: '/informaDet',
    name: 'informaDet',
    component: () => import('@/pages/industryInformation/details'),
    meta: {
      title: '行业资讯 - 北京蓝英科技有限公司',
      charset: 'utf-8',
      name: 'viewport',
      content: 'width=device-width, initial-scale=0,user-scalable=no'
    }
  },
  {
    path: '/case',
    name: '/case',
    component: () => import('@/pages/case/index'),
    meta: {
      title: '优秀案例 - 北京蓝英科技有限公司',
      charset: 'utf-8',
      name: 'viewport',
      content: 'width=device-width, initial-scale=0,user-scalable=no'
    }
  },
  {
    path: '/product',
    name: '/product',
    component: () => import('@/pages/product/index'),
    meta: {
      title: '产品服务 - 北京蓝英科技有限公司',
      charset: 'utf-8',
      name: 'viewport',
      content: 'width=device-width, initial-scale=0,user-scalable=no'
    }
  },
  {
    path: '/caseDetails',
    name: 'caseDetails',
    component: () => import('@/pages/case/details'),
    meta: {
      title: '优秀案例 - 北京蓝英科技有限公司',
      charset: 'utf-8',
      name: 'viewport',
      content: 'width=device-width, initial-scale=0,user-scalable=no'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/pages/about/index'),
    meta: {
      title: '企业介绍 - 北京蓝英科技有限公司',
      charset: 'utf-8',
      name: 'viewport',
      content: 'width=device-width, initial-scale=0,user-scalable=no'
    },
    redirect: '/about/brief',
    children: [
      {
        path: '/about/brief',
        name: '/about/brief',  component: () => import('@/pages/about/CompanyProfile'),
        meta: {
          title: '企业介绍 - 北京蓝英科技有限公司',
          charset: 'utf-8',
          name: 'viewport',
          content: 'width=device-width, initial-scale=0,user-scalable=no'
        }
      },
      {
        path: '/about/Culture',
        name: '/about/Culture', component: () => import('@/pages/about/corporateCulture'),
        meta: {
          title: '企业介绍 - 北京蓝英科技有限公司',
          charset: 'utf-8',
          name: 'viewport',
          content: 'width=device-width, initial-scale=0,user-scalable=no'
        }
      },
      {
        path: '/about/honor',
        name: '/about/honor', component: () => import('@/pages/about/EnterpriseHonor'),
        meta: {
          title: '企业介绍 - 北京蓝英科技有限公司',
          charset: 'utf-8',
          name: 'viewport',
          content: 'width=device-width, initial-scale=0'
        }
      }
    ]
  },
  {
    path: '/join',
    name: '/join',
    component: () => import('@/pages/join/index'),
    meta: {
      title: '加入我们 - 北京蓝英科技有限公司'
    },
    redirect: '/join/contact',
    children: [
      {
        path: '/join/contact',
        name: '/join/contact',
        component: () => import('@/pages/join/contactInformation'),
        meta: {
          title: '加入我们 - 北京蓝英科技有限公司',
          charset: 'utf-8',
          name: 'viewport',
          content: 'width=device-width, initial-scale=0,user-scalable=no'
        }
      },
      {
        path: '/join/recruit',
        name: '/join/recruit',
        component: () => import('@/pages/join/recruitWiseMen'),
        meta: {
          title: '加入我们 - 北京蓝英科技有限公司',
          charset: 'utf-8',
          name: 'viewport',
          content: 'width=device-width, initial-scale=0,user-scalable=no'
        }
      }
    ]
  }
]

export const routers = [loginRouter, errorRouter, otherRouter, ...appRouter, ]

