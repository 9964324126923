<template>
  <div class="home1">
<!--    logo和导航栏-->
<!--    显示隐藏-->
<!--    <div class="navTitle" :class="[show ? 'navigationFalse' : 'navigationTrue']">-->
<!--      <navigation />-->
<!--    </div>-->
    <div :class="show ? 'navTitle' : 'navTitle1'">
      <navigation />
    </div>
<!--    轮播图-->
    <div>
      <carousel />
    </div>
    <div>
      <briefIntroduction />
    </div>
<!--    了解我们-->
    <div class="home">
      <understand />
    </div>
<!--    合作模式-->
    <div class="home">
      <cooperate />
    </div>

    <!--    行业资讯-->
    <div class="home">
      <information />
    </div>

<!--    我们的优势-->
    <div class="home">
      <WeAdvantage />
    </div>

<!--    成功案例-->
    <div class="home">
      <successfulCase />
    </div>
<!--    与我们联系-->
    <div class="home">
<!--      <contact />-->
    </div>
<!--    页脚-->
    <div>
      <footers />
    </div>

<!--    返回顶部按钮-->
<!--    <el-backtop target=".home1" :bottom="100">-->
<!--      <div class="up"></div>-->
<!--    </el-backtop>-->
  </div>
</template>

<script>
import navigation from '@/components/home/navigation/index';
import carousel from '@/components/home/carousel/index'
import understand from '@/components/home/understand/index'
import information from '@/components/home/information/index'
import successfulCase from '@/components/home/successfulCase/index';
import contact from '@/components/home/contact/index';
import footers from '@/components/home/footers/index';
import briefIntroduction from '@/components/home/briefIntroduction/index';
import cooperate from '@/components/home/cooperate/index';
import WeAdvantage from '@/components/home/weAdvantage/index';
export default {
  components: {
    navigation,
    carousel,
    understand,
    information,
    successfulCase,
    contact,
    footers,
    briefIntroduction,
    cooperate,
    WeAdvantage
  },
  data(){
    return{
      show:false
    }
  },
  mounted() {
    window.addEventListener('scroll', () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > 100) {
        this.show = true
      } else {
        this.show = false
      }
    })
    // let oldTop = 0; //旧数据，初始为0
    // // 将自定义方法绑定到窗口滚动条事件
    // window.onscroll = () => {
    //   let top = document.scrollingElement.scrollTop; //触发滚动条，记录数值
    //   // //旧数据大于当前位置，表示滚动条top向上滚动
    //   if (oldTop > top) {
    //     this.show = false;
    //     console.log("↑");
    //     // top: -18%;
    //   } else {
    //     //相反...
    //     this.show = true;
    //     console.log("↓");
    //   }
    //   oldTop = top;//更新旧的位置
    // };
  }
}
</script>

<style lang="less" scoped>
.home1 {
  //height: 100vh;
  //overflow-x: hidden;
}
.up {
  width: 100%;
  height: 100%;
  background: url('../../static/image/home/icon_lianxikefu.png') no-repeat;
  background-size: 100% 100%;
}
</style>
