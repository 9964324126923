<template>
  <div class="layout">
    <div
      class="siderbar-wrapper"
      :style="{ width: this.isCollapsed ? '64px' : '200px' }"
    >
      <div class="logo-wrapper" @click="routerHome">
        <img
          src="../../static/LOGO.png"
          style="width: 30px; height: 30px; margin-top:auto;margin-bottom:auto;top:0;bottom:0;position:absolute;left: 20px;"
        />
        蓝英科技
      </div>
      <el-menu
        background-color="#424f63"
        text-color="#fff"
        active-text-color="#65cea7"
        class="menu-wrapper"
        router
        unique-opened
        :collapse="isCollapsed"
        :default-active="$route.path"
      >
        <template v-for="(item, index) in sider_menu_data">
          <el-menu-item
            class="menu-item"
            v-if="!item.children"
            :index="item.path"
            :key="index"
          >
            <i :class="item.icon"></i>
            <span slot="title">{{ item.title }}</span>
          </el-menu-item>
          <el-submenu v-else :index="item.path" :key="index">
            <template slot="title">
              <i :class="item.icon"></i>
              <span slot="title">{{ item.title }}</span>
            </template>
            <el-menu-item
              class="menu-item"
              v-for="(sub_item, sub_index) in item.children"
              :index="sub_item.path"
              :key="sub_index"
            >
              <span slot="title" style="margin-left:12px;">{{
                sub_item.title
              }}</span>
            </el-menu-item>
          </el-submenu>
        </template>
      </el-menu>
    </div>
    <div
      class="topbar-wrapper"
      :style="{ left: this.isCollapsed ? '64px' : '200px' }"
    >
      <!--      <div class="menu-collapse-wrapper float-left" @click="toggleMenu">-->
      <!--        <i-->
      <!--          class="el-icon-adm-menu"-->
      <!--          :style="{-->
      <!--            transform: 'rotateZ(' + (this.isCollapsed ? '90' : '0') + 'deg)'-->
      <!--          }"-->
      <!--        ></i>-->
      <!--      </div>-->
      <div class="title float-left" style="margin-left: 30px">
        蓝英科技后台管理系统
      </div>
      <ul class="menu-list float-right">
        <!--        <li class="menu-items">-->
        <!--          <div style="padding: 0 15px;">-->
        <!--            <div class="dropdown-content el-dropdown-link">-->
        <!--              &lt;!&ndash;              <i class="icon el-icon-mobile"></i>&ndash;&gt;-->
        <!--              <img class="icons" src="@/assets/icon/mobile.png" alt="" />-->
        <!--              <span class="text">在线人数：{{ onLineNumber }}</span>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </li>-->
        <li class="menu-item" style="padding: 0;height: 48px;">
          <!--          <el-dropdown-->
          <!--            :show-timeout="10"-->
          <!--            :hide-timeout="100"-->
          <!--            @command="handleCommand"-->
          <!--            style="padding: 0 15px;"-->
          <!--          >-->
          <!--            <div class="dropdown-content el-dropdown-link">-->
          <!--&lt;!&ndash;              <i class="icon el-icon-adm-usersetup"></i>&ndash;&gt;-->
          <!--              <i class="icon el-icon-user-solid"></i>-->
          <!--              <span class="text">{{ list.realName || list.loginName }}</span>-->
          <!--            </div>-->
          <!--            <el-dropdown-menu slot="dropdown">-->
          <!--              <el-dropdown-item command="a">-->
          <!--                修改密码-->
          <!--              </el-dropdown-item>-->
          <!--            </el-dropdown-menu>-->
          <!--          </el-dropdown>-->
          <el-menu
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
          >
            <el-submenu index="2">
              <template slot="title">
                <div class="dropdown-content el-dropdown-link">
                  <img class="icons" src="@/assets/icon/staff.png" alt="" />
                  <span class="text">{{ userInfo.loginName }}</span>
                </div>
              </template>
              <el-menu-item index="2-1">修改密码</el-menu-item>
            </el-submenu>
          </el-menu>
        </li>
        <li class="menu-items" @click="exit">
          <!--          <i class="icon iconfont el-icon-adm-exit"></i>-->
          <!--          <i class="icon el-icon-switch-button"></i>-->
          <!--          <span>退出</span>-->
          <div style="padding: 0 15px;">
            <div class="dropdown-content el-dropdown-link">
              <!--              <i class="icon el-icon-switch-button"></i>-->
              <img class="icons" src="@/assets/icon/exit.png" alt="" />
              <span class="text">退出</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div
      class="content-wrapper"
      ref="content-wrapper"
      :style="{ left: this.isCollapsed ? '64px' : '200px' }"
    >
      <div class="content">
        <router-view></router-view>
      </div>
    </div>

    <el-dialog
      title="修改密码"
      :visible.sync="dialogVisible"
      width="30%"
      @close="close('formName')"
    >
      <el-form
        label-position="right"
        label-width="80px"
        :model="form"
        :rules="rules"
        ref="formName"
      >
        <el-form-item label="账号：" class="layoutForm123" prop="loginName">
          <el-input :disabled="true" v-model="form.loginName"></el-input>
        </el-form-item>
        <el-form-item label="旧密码：" class="layoutForm123" prop="oldPassword">
          <el-input v-model="form.oldPassword" show-password></el-input>
        </el-form-item>
        <el-form-item label="新密码：" class="layoutForm123" prop="newPassword">
          <el-input v-model="form.newPassword" show-password></el-input>
        </el-form-item>
        <el-form-item
          label="确认密码："
          class="layoutForm123"
          prop="onceMorePassword"
        >
          <el-input v-model="form.onceMorePassword" show-password></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="formSave('formName')"
          >确 定</el-button
        >
        <el-button @click="formCancel('formName')">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { sessionStorage } from '../../common/utils/storage'
import '../../router/route.js'
import { getSysUser, updatePassword } from '@/api/layout'
import { foregroundNavigationQueryListForShow } from '@/api/homeNav'
export default {
  created() {
    this.checkAuth()
    this.setRouteTitle()
  },
  data() {
    return {
      sider_menu_data: [
        {
          path: '/main/industryInformation/rotationMap',
          title: '首页导航',
          icon: 'el-icon-s-home',
          id: 1
        },
        {
          path: '/main/industryInformation/industryInformationAdmin',
          title: '行业资讯',
          icon: 'el-icon-reading',
          id: 2
        },
        {
          path: '/main/case/caseAdmin',
          title: '优秀案例',
          icon: 'el-icon-tickets',
          id: 3
        },
        {
          path: '/main/about/about',
          title: '关于蓝英',
          icon: 'el-icon-office-building',
          id: 4
        },
        {
          path: '/main/join/joinAdmin',
          title: '招贤纳士',
          icon: 'el-icon-s-promotion'
        },
        // {
        //   path: '/main/contactUs/index',
        //   title: '联系我们',
        //   icon: 'el-icon-s-home'
        // },
        // {
        //   path: '/main/case/otherProductsAdmin',
        //   title: '其他产品',
        //   icon: 'el-icon-s-home'
        // },
        {
          path: '/main/sysAdmin',
          title: '系统用户',
          icon: 'el-icon-user'
        },
        {
          path: '/main/home/homeNav',
          title: '导航设置',
          icon: 'el-icon-setting'
        },
        // {
        //   path: '/main/customer',
        //   title: '客服',
        //   icon: 'el-icon-setting'
        // }
      ],
      userInfo: {},
      isCollapsed: false,
      adminMenuShow: false,
      list: null,
      user: null,
      dialogVisible: false,
      form: {
        loginName: null,
        oldPassword: null,
        newPassword: null,
        onceMorePassword: null
      },
      rules: {
        loginName: [{ required: true, message: '请输入账号', trigger: 'blur' }],
        oldPassword: [
          { required: true, message: '请输入旧密码', trigger: 'blur' }
        ],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' }
        ],
        onceMorePassword: [
          { required: true, message: '请确认新密码', trigger: 'blur' }
        ]
      },
      onLineNumber: ''
    }
  },
  computed: {
    // user() {
    //   return this.$store.state.user
    // }
  },
  methods: {
    routerHome() {
      this.$router.push({ path: '/main/home' })
    },

    checkAuth() {
      let token = this.$store.state.token || sessionStorage.getItem('token')
      if (!token) {
        this.$router.replace('/main')
      } else {
        getSysUser().then(res => {
          this.userInfo = res
        })
      }
    },

    //设置侧边导航栏 title
    setRouteTitle() {
      foregroundNavigationQueryListForShow().then(res => {
        this.sider_menu_data.forEach(item => {
          res.forEach(curdItem => {
            if (item.id === curdItem.id) {
              item.title = curdItem.name
            }
          })
        })
      })
    },
    // 下拉框点击事件
    handleCommand(command) {
      this.form.loginName = this.list.loginName
      this.dialogVisible = true
    },
    //保存按钮
    formSave(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.form.newPassword !== this.form.onceMorePassword) {
            this.$alert('两次密码输入不同!', '标题名称', {
              confirmButtonText: '确定'
            })
            return
          }
          this.$confirm('您确定修改吗？', '信息提示', {
            cancelButtonClass: 'btn-custom-cancel',
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            updatePassword(this.form).then(res => {
              if (res != 'success') {
                this.$message.error(res)
                return
              }
              console.log(res)
              this.$alert('修改成功！', '信息提示', {
                confirmButtonText: '确定',
                type: 'success'
              }).then(() => {
                // this.dialogVisible = false
                // this.$refs[formName].resetFields();
                this.$store.commit('SET_TOKEN', '')
                this.$store.commit('SET_USER', null)
                this.$router.replace({ path: '/main' })
              })
            })
          })
        }
      })
    },
    handleSelect(key, keyPath) {
      // console.log(key, keyPath);
      this.form.loginName = this.list.loginName
      this.dialogVisible = true
    },
    // 取消
    formCancel(formName) {
      this.dialogVisible = false
      this.loginName = null
      this.oldPassword = null
      this.newPassword = null
      this.$refs[formName].resetFields()
    },
    // 清除表单验证
    close(formName) {
      this.$refs[formName].resetFields()
    },
    exit() {
      this.$confirm('您确定退出吗？', '提示', {
        cancelButtonClass: 'btn-custom-cancel',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$store.commit('SET_TOKEN', '')
          this.$store.commit('SET_USER', null)
          this.$router.replace({ path: '/main' })
        })
        .catch(() => {
          return false
        })
    }
  }
}
</script>
<style lang="less">
@import '../../common/styles/variable.less';
//设置取消按钮向右浮动，左magin为10px，即与确定按钮间距为10px
.btn-custom-cancel {
  float: right;
  margin-left: 10px;
}

.siderbar-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: @siderbarWidth;
  z-index: 11;
  background-color: @siderbarBackgroundColor;
  transition: all 0.3s ease-in-out;

  .logo-wrapper {
    position: relative;
    height: 40px;
    line-height: 40px;
    padding: 16px 0;
    text-align: center;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
  }

  .menu-wrapper {
    position: absolute;
    top: 72px;
    bottom: 0;
    width: 100%;
    border-right: none;
    transition: all 0.3s ease-in-out;

    &:not(.el-menu--collapse) {
      overflow-y: auto;
      overflow-x: hidden;
    }

    i {
      color: #ffffff;
    }

    .menu-item {
      &.is-active,
      &:hover {
        background-color: #353f4f !important;
        color: #65cea7 !important;

        i {
          color: #65cea7 !important;
        }
      }
    }

    .el-submenu__title:hover {
      color: #65cea7 !important;

      i {
        color: #65cea7 !important;
      }
    }

    .el-submenu,
    .el-menu-item {
      width: 100%;
    }
  }
}

.topbar-wrapper {
  position: fixed;
  left: @siderbarWidth;
  right: 0;
  top: 0;
  height: @topbarHeight;
  line-height: @topbarHeight;
  padding: 0 24px 0 0;
  background-color: @topbarBackgroundColor;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
  transition: all 0.3s ease-in-out;
  z-index: 12;

  .menu-collapse-wrapper {
    height: 100%;
    width: 48px;
    text-align: center;
    cursor: pointer;

    i {
      transition: all 0.3s ease-in-out;
    }
  }

  .title {
    height: 100%;
    font-weight: bold;
  }
  .menu-list {
    .menu-items {
      cursor: pointer;
      position: relative;
      float: left;
      padding: 0;
      min-width: 45px;
      height: 48px;
      text-align: center;
      font-size: 0;

      .icon {
        vertical-align: middle;
        font-size: 24px;
      }

      .icons {
        width: 20px;
        vertical-align: middle;
        font-size: 24px;
      }

      .text {
        display: inline-block;
        vertical-align: middle;
        margin-left: 4px;
        font-size: 16px;
      }
    }
  }
  .menu-list {
    .menu-item {
      position: relative;
      float: left;
      padding: 0 15px;
      min-width: 45px;
      height: 48px;
      text-align: center;
      font-size: 0;

      &:hover {
        cursor: pointer;
        background-color: #f5f5f5;
      }

      .icon {
        vertical-align: middle;
        font-size: 24px;
      }

      .icons {
        width: 20px;
        vertical-align: middle;
        font-size: 24px;
      }

      .text {
        display: inline-block;
        vertical-align: middle;
        margin-left: 4px;
        font-size: 16px;
        color: #000;
      }
    }
  }
}
.layoutForm123 {
  .el-form-item__label {
    width: 93px !important;
  }
  .el-form-item__content {
    margin-left: 93px !important;
  }
}

.content-wrapper {
  position: fixed;
  left: @siderbarWidth;
  right: 0;
  top: @topbarHeight;
  bottom: 0;
  padding: 16px;
  overflow: auto;
  transition: all 0.3s ease-in-out;

  .content {
    position: relative;
    width: 100%;
    height: 100%;
  }
}

.el-menu.el-menu--horizontal {
  height: 100%;
}
.el-menu--horizontal > .el-submenu .el-submenu__icon-arrow {
  display: none;
}
.el-menu--horizontal > .el-submenu .el-submenu__title {
  line-height: 48px;
}
.el-menu--horizontal > .el-submenu .el-submenu__title {
  height: 48px;
}
.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom: 0;
}
.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom: 0;
}
.el-menu--horizontal > .el-submenu {
  height: 48px;
}
.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  height: 48px;
}
.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  height: 48px;
}
.el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .el-submenu__title {
  color: #000;
}

.el-form {
  width: 95%;
}
</style>
