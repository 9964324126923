<template>
  <div>
    <!--    logo和导航栏-->
    <div :class="show ? 'navTitle' : 'navTitle1'">
      <navigation/>
    </div>
<!--   头部大图 -->
    <div>
      <head-img />
    </div>
<!--    &lt;!&ndash;      云端考试系统&ndash;&gt;-->
<!--    <div class="home" style="width: 1160px;">-->
<!--      <exam />-->
<!--    </div>-->
<!--    &lt;!&ndash;    其他产品&ndash;&gt;-->
<!--    <div class="home" style="width: 1200px;">-->
<!--      <otherProducts />-->
<!--    </div>-->
<!--    优秀案例-->
    <div class="home">
      <good-case />
    </div>
    <!--    页脚-->
    <div>
      <footers/>
    </div>
  </div>
</template>

<script>
import navigation from '@/components/home/navigation/index';
import headImg from '@/components/case/headImg/index'
import goodCase from '@/components/case/goodCase/index'
import footers from '@/components/home/footers/index';
import otherProducts from '@/components/home/otherProducts/index';
import exam from '@/components/home/exam/index'

export default {
  name: "index",
  components: {
    navigation,
    headImg,
    goodCase,
    footers,
    otherProducts,
    exam
  },
  data(){
    return{
      show:false,
    }
  },
  mounted() {
    window.addEventListener('scroll', () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > 100) {
        this.show = true
      } else {
        this.show = false
      }
    })
    // let oldTop = 0; //旧数据，初始为0
    // // 将自定义方法绑定到窗口滚动条事件
    // window.onscroll = () => {
    //   let top = document.scrollingElement.scrollTop; //触发滚动条，记录数值
    //   //旧数据大于当前位置，表示滚动条top向上滚动
    //   if (oldTop > top) {
    //     this.show = false;
    //     console.log("↑");
    //     // top: -18%;
    //   } else {
    //     //相反...
    //     this.show = true;
    //     console.log("↓");
    //   }
    //   oldTop = top;//更新旧的位置
    // };
  }
}
</script>

<style scoped>
/*html,*/
/*body {*/
/*  overflow-x: hidden;*/
/*}*/
</style>
