import { Loading } from 'element-ui'
import Vue from 'vue'
import VueRouter from 'vue-router'
import { setTitle } from '../common/utils'
import { routers } from './route'


Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routerConfig = {
  mode: 'history',
  // mode: 'hash',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  routes: routers
}

const router = new VueRouter(routerConfig)

let loading
router.beforeEach((to, _form, next) => {
  loading = Loading.service({
    // fullscreen: true,
    target: '.content-wrapper',
    text: '跳转中...'
  })

  if (to.matched.some((r) => r.meta.requireAuth)) {
    let token = JSON.parse(sessionStorage.getItem('token'));
    if (token) {   //判断是否已经登录
      next();
    } else {
      next({
        path: '/main'
      });
      loading.close();
    }
  } else {
    console.log('这是拦截');
    next();
  }

  // 设置window.document.title 的名称
  setTitle(to.meta.title)

  if (!to.matched.length) {
    next({
      path: '/error/404',
      replace: true
    })
  } else {
    next()
  }

  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  // 兼容IE
  window.scrollTo(0, 0);
  next();
})

router.afterEach(() => {
  // 解决某些情况下loading无法关闭的情况
  setTimeout(() => {
    loading.close()
  }, 0)
})

export default router
