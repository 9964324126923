<template>
  <div class="wrap">
<!--    <AMap></AMap>-->
    <div class="content">
        <div class="content-top">
          <div >
            <span class="font-USA">CONTACT</span>
            <span class="font-h2">与我们联系</span>
          </div>
          <div>
            <div style="color: rgb(102,102,102); margin-top: 20px;  display: block">欢迎与我们取得联系，我们将竭诚为你服务</div>
          </div>
        </div>
        <div class="content-bottom">
          <div class="content-common "  style="margin-left: 89px;margin-top: 10px">
            <div class="image-common" >
              <img src="../../static/image/dw.png" height="20" width="20"/>
              <span>来访地址：{{ dataObj.address }}</span>
            </div>
            <div class="image-common">
              <img src="../../static/image/telephone2.png" height="20" width="20"/>
              <span>联系电话：{{ dataObj.tel }}</span>
            </div>
            <div class="image-common">
              <img src="../../static/image/em.png" height="20" width="20"/>
              <span>服务邮箱：{{ dataObj.email }}</span>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import AMap from '@/components/AMap'
import { contactUsQueryObject } from '@/api/contactInformation'
export default {
  components: {
    AMap
  },
  data() {
    return {
      dataObj:{}
    };
  },
  mounted() {
    contactUsQueryObject().then(res => {
      this.dataObj = res
    })
  },
  methods: {},
};
</script>
<style scoped lang="less">
  .wrap{
    //margin-bottom: 120px;
    position: relative;
    margin-bottom: 45px !important;
    .content{
      display: flex;
      margin-top: 40px;
      margin-left: 7px;
      width: 90%;
      background-color: #fff;
      /* position: absolute; */
      bottom: -155px;
      left: 4%;
      z-index: 9999999;
      box-shadow: 0px 10px 26px #cccccc;
      padding: 50px;
      .font-USA{
        font-size: 66px;
        font-weight: bold;
        color: rgb(243,243,243);
      }
      .font-h2{
        font-size: 25px;
        position: absolute;
        font-weight: bold;
        left: 50px;
        top: 80px;
      }
      .image-common{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 25px;
        img{
          display: block;
          border-radius: 20px;
          background: rgb(243,243,243);
          padding: 5px;
          margin-right: 15px;
        }
      }
      .content-common{
        display: inline-block;
      }
      .content-top{
        //display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
      }
      .content-bottom{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        margin-bottom: 15px;
      }
    }
  }
</style>
