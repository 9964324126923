<template>
  <div class="weAdvantage">
    <headerVue title="我们的优势" content="OUR ADVANTAGE" type="1" style="margin-bottom: 30px" />
    <div class="box1">
      <div
        class="box"
        v-for="(item, index) in columns"
        :key="index"
        :style="{ backgroundColor: item.bgColor }"
      >
        <div class="big">
          <img :src="item.image" alt="" />
        </div>
        <div class="smallbox">
          {{ item.title }}
        </div>
        <div class="box3">
          <span>{{ item.text }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import headerVue from '@/components/header.vue'
export default {
  name: 'index',
  props: ['title', 'type', 'content'],
  components: {
    headerVue
  },
  data() {
    return {
      columns: [
        // 列表展示的column
        {
          title: '二十年数字化服务经验',
          image: require('@/static/image/home/shuzihua.png'),
          text: '我们作为中石化、中石油等大型集团长期的技术服务合作商，拥有丰富的大中型国央企数字化服务经验。项目涉及能源、制造、医药、商业、农业、教育等多个领域。',
          bgColor: '#2C5EDC',
        },
        {
          title: '领先的技术',
          image: require('@/static/image/home/zuoyechengben.png'),
          text: 'kc-phenix-bd平台集成国际先进的分布式存储系统Hbase、大数据计算引擎Spark等，通过对先进技术框架整合改造，并结合我们多年行业积累的专业化的的组件和算法，打造国际领先的企业级大数据一体化解决方案。',
          bgColor: '#4F6EF6',
        },
        {
          title: '稳定的客户关系',
          image: require('@/static/image/home/kehu.png'),
          text: '用科技引领进步，用服务赢得安心，北方科诚重视客户的极致体验，经过多年的努力，已具备庞大的客户群体，并持续稳定合作，这为我们的业务扩展，提供了强有力的保障。',
          bgColor: '#24D892',
        },
        {
          title: '开放式的合作',
          image: require('@/static/image/home/hezuo.png'),
          text: '我们的合作模式完善，合作方式灵活。我们注重真诚、互利，彼此合作开诚布公，共同实现高效协作的合作氛围，打造优良的生态共赢体系。',
          bgColor: '#3FD753',
        },
      ],
    }
  },

  computed: {},
  mounted() {},
  methods: {},
}
</script>
<style scoped lang="less">
.weAdvantage {
  margin-top: 80px;
}
@keyframes myAnimation {
  50% {
    transform: rotateY(90deg);
  }

  100% {
    animation-play-state: paused;
  }
}

.box:hover {
  transition: all 0.3s;
  -wbkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  cursor: pointer;
  color: rgba(255, 255, 255);
  box-shadow: 0px 5px 20px 0px rgba(52, 51, 179, 0.65);
  .big {
    transform: rotateY(360deg);
  }
}

.box1 {
  display: flex;
  //width: 90%;
  //margin-left: 80px;
  margin-bottom: 20px;
  height: 90%;
}
.box {
  display: flex;
  visibility: visible;
  animation-delay: 1.4s;
  flex-direction: column;
  align-content: stretch;
  justify-content: normal;
  align-items: center;
  width: 25%;
  height: 360px;
  border-radius: 4%;
  position: relative;
  margin: 0 1%;
  background: deeppink;
}

.big {
  display: flex;
  transition: all 0.5s;
  width: 60px;
  height: 66px;
  margin: 40px auto 20px;
  justify-content: center;
  align-items: center;
  background-size: 100% 100%;
}

.smallbox {
  font-size: 19px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  line-height: 28px;
  margin-bottom: 10px;
}

p {
  text-align: center;
}

img {
  width: 100%;
  height: 100%;
}
.box3 {
  display: block;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8;
  color: rgba(255, 255, 255, 0.8);
  width: 80%;
  margin: 0 auto;
  // margin-top: 80px;
  // margin-left: 20px;
  // width: 200px;
  // height: 500px;
  // background: yellow;
}
span {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8;
  color: rgba(255, 255, 255, 0.8);
  width: 80%;
  margin: 0 auto;
}
</style>