<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>

export default {
  name: "recruitment",
  mounted() {
    document.dispatchEvent(new Event('render-event'))
    //App.vue
    let agent = navigator.userAgent.toLowerCase();
    let isMac = /macintosh|mac os x/i.test(navigator.userAgent);
    let app = document.getElementById('app');
    if (agent.indexOf("win32") >= 0 || agent.indexOf("wow32") >= 0) {
      app.style.fontFamily = "MyFont";
    }
    if (agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0) {
      app.style.fontFamily = "MyFont";
    }
    if(isMac){
      app.style.fontFamily = "PingFang";
    }
    //App.vue
      if (this._isMobile()) {
        // alert("手机端");
        console.log('手机端',window)
        // window.visualViewport.width=1519
      } else {
        // alert("pc端");
        console.log('pc',window)
      }
  },
  methods:{
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }
  }
}
</script>

<style lang="less">

</style>
