<template>
  <div class="carousel">
    <el-carousel trigger="click" height="600px">
      <el-carousel-item v-for="(item, index) in newList" :key="index">
<!--        <h3 class="small">{{ item }}</h3>-->
        <img :src="item.picPathHttpUrl" style="width: 100%; height: 100%;" alt="">
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
import { contactUsQueryObject } from '@/api/contactInformation'
import { rotatePage, newsQueryList, rotateQueryList } from "../../../api/contactInformation";
export default {
  name: "index",
  data(){
    return{
      newList:[],
      recordsTotal: null, // 数据总数
      current:1,
      size:6,
      params:{
        name:''
      }
    }
  },
  created() {
    rotateQueryList().then(res => {
        this.newList = res
    })


  }
  // methods:{
  //   contactUsQueryObject
  // }
}
</script>

<style scoped lang="less">
.carousel {
  min-width: 1200px;
  margin: 0 auto;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
/deep/.el-carousel__container{
  //top: 75px;
}
/deep/.el-carousel--horizontal{
  overflow: hidden !important;
}
/deep/.el-carousel__arrow{
  //top: 36%;
}
</style>
