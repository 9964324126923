import {get,post} from "../http/index";

//删除
export const newDelteById = (params) => get('/successStories/delete/' + params);

//详情
export const newsQueryById = (params) => get('/successStories/detail/' + params);

//保存
export const newsSave = (params) => post('/successStories/save',params)

//修改保存
export const newsUpdate = (params) => post('/successStories/update',params)

//分页
// export const newsQueryList = (pageNumber, size) => post('/news/queryList?page=' + pageNumber + '&pageSize='+ size);
export const newsQueryList = (current, size, params) => post('/successStories/page?current=' + current + '&size=' + size , params);

// 首页使用集合
export const showList = (current, size, params) => get('/successStories/showList');

//新闻类型
export const newsTypeList = () => get('/newsType/list')

//新闻类型集合
export const newsNewsTypeList = () => get('/news/newsTypeList')

// 图片上传集合
export const uploadFile = (params, folder) => post('/fileProcessing/uploadFile', params, folder)

// 获取图片链接
export const webServerHttpUrlString = () => get('/fileProcessing/webServerHttpUrl')

//详情
export const queryById = (id) => get('/news/detail/' + id)

// 获取关于蓝英集合
export const aboutUsQueryList = () => get('/aboutUs/queryList')

// 详情
export const aboutUsQueryById = (params) => get('/aboutUs/detail/' + params);
